import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from './assets/js/logout';
import toast from 'react-hot-toast';

function useIdleTimer(timeout = 1800 * 1000) {
    const navigate = useNavigate();
    const logoutHandle = async () => {
        const response = await logout();
        const toastId = toast.loading("Signing off...");
        setTimeout(async () => {
            toast.remove(toastId);
            if (response.status) { navigate("/"); }
        }, 800);
    };
    const resetTimer = useCallback(() => {
        clearTimeout(window.idleTimer);
        window.idleTimer = setTimeout(() => { logoutHandle(); }, timeout);
    }, [logoutHandle, timeout]);
    useEffect(() => {
        resetTimer();
        window.addEventListener('mousemove', resetTimer);
        window.addEventListener('keydown', resetTimer);
        window.addEventListener('click', resetTimer);
        sessionStorage.removeItem('isTabClosed');
        const handleBeforeUnload = () => {sessionStorage.setItem('isTabClosed', 'true');};
        const handleUnload = () => {
            if (sessionStorage.getItem('isTabClosed') === 'true') {logoutHandle();}
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('unload', handleUnload);

        return () => {
            clearTimeout(window.idleTimer);
            window.removeEventListener('mousemove', resetTimer);
            window.removeEventListener('keydown', resetTimer);
            window.removeEventListener('click', resetTimer);
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('unload', handleUnload);
        };
    }, [resetTimer, logoutHandle]);

    return null;
}

export default useIdleTimer;
