const devConfig = {
    app_name: "Vizii",
    frontendVersion: "18-September-2024-Development",
    base_url: "http://localhost:3000/api",
    // base_url: "https://dashboard.vizii.com/apis/api",
    // base_url: "https://staging.vizii.com/apis/api",
    app_url: "http://localhost:3001",
    environment: "dev",
    // monthly_price_id: "price_1PjFfzASyMRcymO6LSzV9WkX", // THIS KEY IS FOR DAILY PAYMENT TESTING PURPOSE
    monthly_price_id: "price_1PjFkdASyMRcymO6WRLuuOhS",
    yearly_price_id: "price_1PjFjIASyMRcymO6RJqcQz2o",
    monthly_unlimited_price_id: "price_1PjFjwASyMRcymO6CLSWgKZ0",
    yearly_unlimited_price_id: "price_1PjFhhASyMRcymO6ojEA1zEj",
    STRIPE_PUB_KEY: "pk_test_51OjJpTASyMRcymO6x2PBK1nrHChycNMNXj7HHvTRffOp5xufCj3WRSCLoep1tGp5Ilx3IWj7ck5yqrwEH8VSRKn80055Kvyelu",
    encryption_key: "wepull_secret_key_v1",
    date:"7-August-2024"
}

const stagingConfig={
    app_name: "Vizii",
    frontendVersion: "12-December-2024-Staging-Build-Version-4",
    base_url: "https://staging.vizii.com/apis/api",
    app_url: "https://staging.vizii.com",
    environment: "dev",
    monthly_price_id: "price_1PjFkdASyMRcymO6WRLuuOhS",
    yearly_price_id: "price_1PjFjIASyMRcymO6RJqcQz2o",
    monthly_unlimited_price_id: "price_1PjFjwASyMRcymO6CLSWgKZ0",
    yearly_unlimited_price_id: "price_1PjFhhASyMRcymO6ojEA1zEj",
    STRIPE_PUB_KEY: "pk_test_51OjJpTASyMRcymO6x2PBK1nrHChycNMNXj7HHvTRffOp5xufCj3WRSCLoep1tGp5Ilx3IWj7ck5yqrwEH8VSRKn80055Kvyelu",
    encryption_key: "wepull_secret_key_v1",
}

const productionConfig={
    app_name: "Vizii",
    frontendVersion: "13-Dec-2024-Production-Build-Version-1",
    base_url: "https://dashboard.vizii.com/apis/api",
    app_url: "https://dashboard.vizii.com",
    environment: "prod",
    // Production Stripe keys
    monthly_price_id: "price_1MTJrwA94Y1iT6R5vflq12WZ",
    yearly_price_id: "price_1Om8XjA94Y1iT6R5CUYmxwes",
    monthly_unlimited_price_id:"price_1OUoIFHp0rU7NH90wJ0m1JKM",
    yearly_unlimited_price_id:  "price_1OUoSQHp0rU7NH90ogxaxcRb",
    STRIPE_PUB_KEY:"pk_live_51LWzzKA94Y1iT6R5ORy2MIM0WMf31L57bqCPa5olaOcXtuAcGBpfhKMRRA5XdbM0d3hBKmpyCRxIMRehmmlKjoKM001ImnV9Kt",
    encryption_key: "wepull_secret_key_v1",
}


// export default devConfig;
export default stagingConfig;
// export default productionConfig;
 