import { combineReducers, configureStore } from '@reduxjs/toolkit';
import dateTimeSlice from './slices/dateTimeSlice';
import chartDownloadSlice from './slices/chartDownloadSlice';
import userSlice from './slices/userSlice';
import windowScrollSlice from './slices/windowScrollSlice';
import activeUserSlice from './slices/activeUserSlice';
import tableFilterSlice from './slices/tableFilterSlice';
import accountListSlice from './slices/accountListSlice'
import toogleImage  from './slices/modalSlice';
import imageUrlSlice from './slices/imageUrlSlice';
import chartDataSlice from './slices/chartDataSlice';
import tableViewSlice from './slices/mergedTableSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { persistStore } from 'redux-persist';
import { createFilter } from 'redux-persist-transform-filter'; // Import for filtering
const tableFilterFilter = createFilter('tableFilter'); // Filter for tableFilter slice

const persistConfig = {
  key: "root",
  storage,
  transforms: [tableFilterFilter],
};

const rootReducer = combineReducers({
  dateTime: dateTimeSlice,
  chartName: chartDownloadSlice,
  userSlice: userSlice,
  windowScroll: windowScrollSlice,
  activeUser: activeUserSlice,
  tableFilter: tableFilterSlice,
  accountList:accountListSlice,
  toogleImage:toogleImage,
  imageUrlSlice:imageUrlSlice,
  chartDataSlice:chartDataSlice,
  tableViewSlice:tableViewSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };
