import { createSlice } from "@reduxjs/toolkit";


const initialState = {merged:true}



const toogleTableView = createSlice({
    initialState,
    name: "tableViewToogle",
    reducers: {
        toogleView(state, action) {
            return {
                merged: action.payload.merged !== undefined ? action.payload.merged : state.merged,
            };
        }
    }
})


export const {toogleView} = toogleTableView.actions

export default toogleTableView.reducer