import { createSlice } from "@reduxjs/toolkit";


const initialState = {show:false }



const toogleChartData = createSlice({
    initialState,
    name: "chartToogle",
    reducers: {
        toogle(state, action) {
            console.log(action,'action')
            return {
                show: action.payload.show !== undefined ? action.payload.show : state.show,
            };
        }
    }
})


export const {toogle} = toogleChartData.actions

export default toogleChartData.reducer