import { createSlice } from "@reduxjs/toolkit";

const initialState = [
    { id: 15, name: " Item Name", key: "ItemName", checked: true },
    { id: 14, name: "Number", key: "Number", checked: true },
    { id: 1, name: "Contact", key: "Contact", checked: true },
    { id: 2, name: "Deep Link", key: "DeepLink", checked: true },
    { id: 3, name: "Date", key: "Date", checked: true },
    { id: 4, name: "Payment / Due Date", key: "DueDate", checked: true },
    { id: 5, name: "Amount", key: "Amount", checked: true },
    { id: 6, name: "Tax", key: "Tax", checked: true },
    { id: 7, name: "Total", key: "Total", checked: true },
    { id: 48, name: "Files", key: "Files", checked: true },
    { id: 9, name: "Expense Type", key: "Expense", checked: true },
    { id: 10, name: "Description", key: "Desc", checked: true },
    { id: 11, name: "Account", key: "Account", checked: true },
    { id: 12, name: "Category 1 ", key: "Cat1", checked: true },
    { id: 13, name: "Category 2 ", key: "Cat2", checked: true },
    // { id: 14, name: "Spend Money", key: "spend", checked: true },
    // { id: 15, name: "Recieve Money", key: "recieve", checked: true },
];

const tableFilterSlice = createSlice({
    name: "tableFilter",
    initialState,
    reducers: {
        toggleFilter(state, action) {
            const { id } = action.payload;
            const checkedCount = state.filter(field => field.checked).length;
            if (checkedCount === 5 && state.find(field => field.id === id)?.checked) {
                return state;
            }
            const itemIndex = state.findIndex(item => item.id === id);
            if (itemIndex !== -1) {
                state[itemIndex].checked = !state[itemIndex].checked;
            }
        },
    },
});

export const { toggleFilter } = tableFilterSlice.actions;
export default tableFilterSlice.reducer;
